import React, {useState}  from 'react';
import {Button, Card, CardContent, CardActions, Typography, IconButton, Snackbar, Stack} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import vCard from 'vcf';
import parse from 'html-react-parser';
import moment from 'moment';
import ReactGA from "react-ga4";


function ShowResult(props) {
    
    const [title, setTitle] =  useState('');
    const [content, setContent] =  useState('');
    const [actions, setActions] =  useState('');
    const [open, setOpen] = React.useState(false);
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    const sb_action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    //const classes = useStyles();
    const result = props.result;
    const type = props.type;

    

    React.useEffect(() => {
        
        // GA
        ReactGA.initialize("G-G439GM2GHV");
        ReactGA.event({
            category: "ScannedQR",
            action: type,
        
        });

        if (type === "phone"){
            setTitle("Phone number");
            setContent(result.substring(0,4) === "tel:" ? result.substring(4) : result);
            setActions(
                () => {
                    return (
                        <Stack spacing={2} direction="row">
                            <Button variant="text" size="medium" color="error" onClick={ () =>  {navigator.clipboard.writeText(result.substring(0,4) === "tel:" ? result.substring(4) : result); setOpen(true);} } >Copy</Button>
                            <Button variant="contained" size="medium" color="error" href={result.substring(0,4) === "tel:" ? result : "tel:"+result} target="_blank">Call now</Button>
                        </Stack>
                    );
                }
            );
        } else if(type === "url") {
            setTitle("Link (URL)");
            setContent(result);
            setActions(
                () => {
                    return (
                        <Stack spacing={2} direction="row">
                            <Button variant="text" size="medium" color="error" onClick={ () =>  {navigator.clipboard.writeText(result); setOpen(true);} } >Copy</Button>
                            <Button variant="contained" size="medium" color="error" href={result} target="_blank">Open link</Button>
                        </Stack>
                    );
                }
            );
        }  else if(type === "mail") {  
            var email = "", sub = "", text = "";   
            if (result.substring(0,7) === "mailto:" || result.substring(0,7) === "MAILTO:") {
                const tab = result.split("?");
                const part1 = tab[0];

                const part1Tab = part1.split(":");
                if (part1Tab.length === 2) {
                    email = part1Tab[1];
                }

                if (tab.length > 1) {
                    var part2 = tab[1];
                    var part2Tab = part2.split("&");

                    if (part2Tab.length > 0) {
                        var param1 = part2Tab[0].split("=");
                        if (param1.length === 2) {
                            if (param1[0] === "subject") {
                                sub = param1[1];
                            } else if (param1[0] === "body") {
                                text = param1[1];
                            }
                        }

                        if (part2Tab.length > 1) {
                            var param2 = part2Tab[1].split("=");
                            if (param2.length === 2) {
                                if (param2[0] === "subject") {
                                    sub = param2[1];
                                } else if (param2[0] === "body") {
                                    text = param2[1];
                                }
                            }
                        }
                    }
                }

            } else if (result.substring(0,7) === "MATMSG:") {       
                const tab = result.split(";");
                
                for(var i=0; i < tab.length; i++){
                    var data = tab[i];
                    if (data.length > 10) {
                        if (data.substring(0,10) === "MATMSG:TO:") {
                            email = data.substring(10);
                        }
                    }
                    if (data.substring(0,4) === "SUB:") {
                        sub = data.substring(4);
                    }
                    if (data.length > 5) {
                        if (data.substring(0,5) === "BODY:") {
                            text = data.substring(5);
                        }
                    }
                }
            }
            // Log.v("kml_qr","mail:"+email+" - sub: " + sub + " - text: " + text);
            const displayText = () => {
                return (
                    <span>
                        <strong>Email:</strong> {email} <br/>
                        <strong>Subject:</strong> {sub} <br/>
                        <strong>Message:</strong> {text}
                    </span>
                );
            };

            var emailLink = "";
            if(result.substring(0,7) === "mailto:") {
                emailLink = result;
            }else if(result.substring(0,7) === "MATMSG:") {
                emailLink = "mailto:"+email+"?subject="+sub+"&body="+text;
            }


            setTitle("E-mail");
            setContent(displayText);
            setActions(
                () => {
                    return (
                        <Stack spacing={2} direction="row">
                            <Button variant="text" size="medium" color="error" onClick={ () =>  {navigator.clipboard.writeText(email); setOpen(true);} } >Copy</Button>
                            <Button variant="contained" size="medium" color="error" href={emailLink} target="_blank">Send E-mail</Button>
                        </Stack>
                    );
                }
            );
        } else if (type === "vcard") {
            console.log("-----");
            const vResult = result.replace(new RegExp("\n", 'g'), "\r\n");
            console.log(vResult);
            /* const test =
            "BEGIN:VCARD\r\n" +
            "VERSION:4.0\r\n" +
            "N:Doe;Jonathan;;Mr;\r\n" +
            "FN:John Doe\r\n" +
            "END:VCARD\r\n";
            console.log(test); */

            var vcard = new vCard().parse(vResult);
            
            

            if (vcard) {
                const fullName = vcard.get("fn") ? vcard.get("fn").valueOf() : "";
                const mName = vcard.get("n") ? vcard.get("n").valueOf().replace(";", " ") : "";
                const listTel = vcard.get("tel");
                const listAddress = vcard.get("adr");
                const listEmail = vcard.get("email");
                const url = vcard.get("url") ? vcard.get("url").valueOf() : "";
                const org = vcard.get("org") ? vcard.get("org").valueOf() : "";
                const note = vcard.get("note") ? vcard.get("note").valueOf() : ""; 
                const titre = vcard.get("title") ? vcard.get("title").valueOf() : "";

                console.log("fn: "+ fullName);
                console.log("name: "+ mName);
                console.log("tels: "+ listTel + "--" + ((listTel) ? listTel.length : 0));
                console.log("adr: "+ listAddress+ "--" + ((listAddress) ? listAddress.length : 0));
                console.log("email: "+ listEmail);
                console.log("url: "+ url);
                console.log("org: "+ org);
                console.log("note: "+ note);
                console.log("title: "+ titre);

                var displayTitle = "", displayName = "";
                var displayTitleCopy = "", displayNameCopy = "";
                

                // String displayTitle = "";
                // StringBuilder displayName = new StringBuilder();

                 if (org !== "") {
                    displayTitle = "<strong>" + org + "</strong><br/>";
                    displayTitleCopy = org + "\n";
                }

                if (fullName !== "") {
                    displayTitle += "<strong>" + fullName + "</strong>";
                    displayTitleCopy += fullName;
                } else {
                    if (mName !== "") {
                        displayTitle += "<strong>" + mName + "</strong>";
                        displayTitleCopy += mName;
                    }
                    
                }
                
                if (titre !== "" ) {
                    displayTitle += "<br/>" + "<strong>" + titre + "</strong>";
                    displayTitleCopy += "\n" + titre;
                }
                
                if (listAddress) {  

                    const adresse = listAddress.toString().replace("ADR;CHARSET=UTF-8:","").replace("ADR:","").replace("adr:","").replace(new RegExp(";", 'g'), " ").trim();
                    if (adresse !== "") {
                        displayName += adresse;
                        displayNameCopy += adresse;
                    }
                }
                
                if (listTel){
                    if (listTel.length > 0) {
                        for(var i=0; i < listTel.length; i++){
                             if (displayName !== "" && listTel[i] !== "") {
                                displayName += "<br/>";
                                displayNameCopy += "\n";
                            }
                            if (listTel[i]["type"]) {
                                if (listTel[i] !== ""){
                                    const telType = listTel[i]["type"].toString().toUpperCase();
                                    if (telType.substring(0,4) === "WORK") {
                                        displayName += "Work: <a href='tel:" + listTel[i] + "' target='_blank'>" + listTel[i] + "</a>";
                                        displayNameCopy += "Work:" + listTel[i];
                                    } else if (telType.substring(0,4) === "CELL") {
                                        displayName += "Phone: <a href='tel:" + listTel[i] + "' target='_blank'>" + listTel[i] + "</a>";
                                        displayNameCopy += "Phone: " + listTel[i];
                                    } else if (telType.substring(0,4) === "HOME") {
                                        displayName += "Home: <a href='tel:" + listTel[i] + "' target='_blank'>" + listTel[i] + "</a>";
                                        displayNameCopy += "Home: " + listTel[i];
                                    } else if (telType.substring(0,3) === "FAX") {
                                        displayName += "Fax: " + listTel[i];
                                        displayNameCopy += "Fax: " + listTel[i];
                                    }
                                }
                            } else {
                                displayName += "Phone: " + listTel[i];
                                displayNameCopy += "Phone: " + listTel[i];
                            } 
                        }

                    } else {
                        displayName += "Phone: " + listTel;
                        displayNameCopy += "Phone: " + listTel;
                    }
                }
                
                if (listEmail) {  
                    if (displayName !== "") {
                        displayName += "<br/>";
                        displayNameCopy += "\n";
                    }
                    const mEmail = listEmail.toString().toUpperCase().replace("EMAIL;TYPE=WORK,INTERNET:","").replace("EMAIL;TYPE=INTERNET:","").replace(new RegExp("\n", 'g'), "").replace("EMAIL:","").trim();
                    if (mEmail !== "") {
                        displayName += "E-mail: <a href='mailto:" + mEmail.toLowerCase() + "' target='_blank'>" + mEmail.toLowerCase() + "</a>";
                        displayNameCopy += "E-mail: " + mEmail.toLowerCase();
                    }
                }
                
                if (url) {
                    if (displayName !== "") {
                        displayName += "<br/>";
                        displayNameCopy += "\n";
                    }
                    const link = url.substring(0,4) === "http" ? url : "http://"+url; 
                    displayName += "Website: <a href='" + link + "' target='_blank'>" + url + "</a>";
                    displayNameCopy += "Website: " + url;
                }
                if (note) {
                        if (displayName !== "") {
                            displayName += "<br/>";
                            displayNameCopy += "\n";
                        }
                        displayName += "Note: " + note;
                        displayNameCopy += "Note: " + note;
                    
                }

                 var resultCopy = displayTitleCopy + "\n" + displayNameCopy;
                // console.log(resultTxt);

                
            }
            const resultTxt = () => {
                return (
                    <span>
                        {parse(displayTitle)} <br/>
                        {parse(displayName)} 
                    </span>
                );
            };

            setTitle("Contact");
            setContent(resultTxt);
            setActions(
                () => {
                    return (
                        <Button variant="contained" size="large" color="error" onClick={ () =>  {navigator.clipboard.writeText(resultCopy); setOpen(true);} } >Copy</Button>
                    );
                }
            );
        } else if (type === "event") {
            const vEvent = result.replace(new RegExp("\n", 'g'), "-;-");
            const vEventTab = vEvent.split("-;-");
            var displayText = "", displayTextCopy = "";
            for(var i=1; i < vEventTab.length-1; i++){
                const item = vEventTab[i].split(":");
                if (item[1]) {
                    if (displayText !== "") {
                        displayText += "<br/>";
                        displayTextCopy += "\n";
                    }
                    var key=item[0].toLowerCase(), value=item[1];
                    value = key === "dtstart" || key === "dtend" ? moment(value, 'YYYYMMDDTHHmmssZ').format('MMM Do YYYY, h:mm:ss a') : value;
                    key = key === "summary" ? "Title" : key === "description" ? "Description" : key === "location" ? "Location" : key === "dtstart" ? "Start date" : key === "dtend" ? "End date" : "";
                    
                    displayText += "<strong>" + key + ":</strong> " + value; 
                    displayTextCopy += key + ": " + value; 
                }
                //console.log(vEventTab[i] + "--" + (item[1] ? item[1] : null));
            }

            const resultTxt = () => {
                return (
                    <span>
                        {parse(displayText)} <br/>
                    </span>
                );
            };

            setTitle("Event");
            setContent(resultTxt);
            setActions(
                () => {
                    return (
                        <Button variant="contained" size="large" color="error" onClick={ () =>  {navigator.clipboard.writeText(displayTextCopy); setOpen(true);} } >Copy</Button>
                    );
                }
            );
        } else if (type === "sms") {
            console.log(result);

            const sms = result.split(":");
            
            var displayText = "", displayTextCopy = "", smsLink = "";

            if (sms[1]){
                displayText = "<strong>Number:</strong> " + sms[1];
                displayTextCopy = "Number: " + sms[1];
                smsLink = "sms:"+sms[1]
            }

            if (sms[2]){
                displayText += "<br/><strong>Message:</strong> " + sms[2];
                displayTextCopy += "\nMessage: " + sms[2];
                smsLink += "&body="+sms[2];
            }

            setTitle("SMS");
            setContent(parse(displayText));
            setActions(
                () => {
                    return (
                        <Stack spacing={2} direction="row">
                            <Button variant="text" size="medium" color="error" onClick={ () =>  {navigator.clipboard.writeText(displayTextCopy); setOpen(true);} } >Copy</Button>
                            <Button variant="contained" size="medium" color="error" href={smsLink} target="_blank">Send SMS</Button>
                        </Stack>
                    );
                }
            );
        } else if (type === "wifi") {
            console.log(result);
            const wifiTab = result.substring(5).split(";");
            var displayText = "", displayTextCopy = "";
            var T="", S="", P="", H="";
            for(var i=0; i < wifiTab.length; i++){
                console.log(wifiTab[i]);
                var item = wifiTab[i].split(":");
                if (item[1]){
                    if (item[0] === "T") {
                        T = item[1];
                    } else if (item[0] === "S"){
                        S = item[1];
                    } else if (item[0] === "P"){
                        P = item[1];
                    } else if (item[0] === "H"){
                        H = item[1];
                    }
                }
            }

            if (S) {
                displayText = "<strong>Wifi name:</strong> " + S;
            }

            if (P) {
                if (displayText !== "") {
                    displayText += "<br/>";
                }
                displayText += "<strong>Password:</strong> " + P;
            }

            if (T) {
                if (displayText !== "") {
                    displayText += "<br/>";
                }
                displayText += "<strong>Encryption:</strong> " + T;
            }

            if (H) {
                if (displayText !== "") {
                    displayText += "<br/>";
                }
                displayText += "<strong>Hidden:</strong> " + H;
            }

            setTitle("Wifi");
            setContent(parse(displayText));
            setActions(
                () => {
                    return (
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" size="small" color="error" onClick={ () =>  {navigator.clipboard.writeText(S); setOpen(true);} } >Copy Wifi name</Button>
                            <Button variant="contained" size="small" color="error" onClick={ () =>  {navigator.clipboard.writeText(P); setOpen(true);} }>Copy Password</Button>
                        </Stack>
                    );
                }
            );
        } else if (type === "geo") {
            setTitle("GEO");
            setContent(result);
            setActions(
                () => {
                    return (
                        <Stack spacing={2} direction="row">
                            <Button variant="text" size="medium" color="error" onClick={ () =>  {navigator.clipboard.writeText(result); setOpen(true);} } >Copy</Button>
                            <Button variant="contained" size="medium" color="error" href={result} target="_blank">Open Map</Button>
                        </Stack>
                    );
                }
            );
        } else {
            setTitle("Text");
            setContent(result);
            setActions(
                () => {
                    return (
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" size="large" color="error" onClick={ () =>  {navigator.clipboard.writeText(result); setOpen(true);} } >Copy</Button>
                        </Stack>
                    );
                }
            );
        }
    }, [result, type]);

    

    return ( 
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h5" component="div" sx={{ fontSize: 18 }}>
                    {content}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                {actions}
            </CardActions>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Copied to clipboard."
                action={sb_action}
            />
        </Card>
    
        );
}

/* const useStyles = makeStyles({



    resultDiv: {
        flex:1,
        background:'red'
    },
    resultLabel:{
      flex:1,
      fontFamily:'Roboto',
      fontSize:'14px'
    },


  });  */

export default ShowResult;
import React, {useState, useRef}  from 'react';
import {Grid} from '@mui/material';
import { makeStyles } from '@mui/styles';
import QrReader from 'react-qr-reader';
import ScanResult from './components/ScanResult';
import ReactGA from "react-ga4";


function App() { 
  const [scanResultWebCam, setScanResultWebCam] =  useState('');
  const classes = useStyles();
  const qrRef = useRef(null);
  var x=0;

  const onScanFile = () => {
      qrRef.current.openImageDialog();
      setScanResultWebCam("Scanning...");
    }

  const handleErrorWebCam = (error) => {
    console.log(error);
  }
  const handleScanWebCam = (result) => {
    //console.log(">>> "+result);
    if (result){
       
        const returnResult = () => { 
          return ( 
            <ScanResult result={result} />
        ); }
        setScanResultWebCam(returnResult);
        
        
        
    }
    
    if (scanResultWebCam !== "Scanning..." && x === 0) {
      let box = document.querySelector('.qrframe');
          let height = box.offsetHeight+60;
          console.log("h2:"+height+" $$$$ "+scanResultWebCam);
          document.querySelector(".banner-item").style.height = height+"px";
          x=1;
    }
          

  }

  const handleOnLoad = () => {
    setScanResultWebCam("Scanning...");
    let box = document.querySelector('.qrframe');
    let height = box.offsetHeight+60;
    console.log("h1:"+height);
    document.querySelector(".banner-item").style.height = height+"px";
  }

  ReactGA.initialize("G-G439GM2GHV");
  ReactGA.send("homepage");

  return (
    <div className={classes.conatiner}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.qrDiv}>
            <QrReader
            delay={300}
            style={{ width: '100%'}}
            onError={handleErrorWebCam}
            onScan={handleScanWebCam}
            onLoad={handleOnLoad}
            showViewFinder={false}
            />
            <div className={classes.qrViewFinder}></div>
            <h1 className={classes.qrImageLabel}>Scan QR code form image</h1>
            <div className={classes.pickImage}>
              <img src={require('./images/qrscannerimage.webp')} 
                className={classes.qrImage} 
                onClick={onScanFile}
                width="25"
                height="25"
                alt="QR Code Scanner from image" />
            </div>
            <QrReader
              ref={qrRef}
              delay={false}
              style={{ display: 'none' }}
              onError={handleErrorWebCam}
              onScan={handleScanWebCam}
              legacyMode
            />
          </div>
          <h3 className={classes.result}>{scanResultWebCam}</h3>
      </Grid>         
    </div>
  );
}

const useStyles = makeStyles({
  conatiner: {
    maxWidth: '600px',
    justifyContent:'center',
    alignItems:'center',
    margin: '0px',
    padding: '0px'
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems:  'center',
    background: '#1875d2',
    color: '#fff',
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: '1.4em',
    fontFamily:'Roboto',
    margin:0
  },
  result: {
    display: 'flex',
    justifyContent: 'center',
    alignItems:  'center',
    // border:'1px solid rgba(0,0,0,0.45)',
    background: 'rgba(0,0,0,0.45)',
    color: '#fff',
    padding: 10,
    margin:0,
    fontSize:14
  },
  qrDiv: {
    position:'relative',
  },
  qrViewFinder: {
    position:'absolute',
    top:0,
    left:0,
    width:'100%',
    height:'100%',
    zIndex:1,
    boxSizing:'border-box',
    border:'60px solid rgba(0,0,0,0.45)',
    boxShadow: 'rgba(255, 255, 255, 0.65) 0px 0px 0px 5px inset'
  },
  pickImage: {
    position:'absolute',
    bottom:10,
    right:15,
    width:'40px',
    height:'40px',
    zIndex:2,
    background:'white',
    borderRadius: 20,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  qrImage:{
    width:'25px',

  },
  qrImageLabel: {
    position:'absolute',
    bottom:10,
    right:60,
    zIndex:2,
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: 12,  
  },
}); 

export default App;
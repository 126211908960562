import React  from 'react';
import validator from 'validator';
import ShowResult from './ShowResult';

function ScanResult(props) {
    
    const result = props.result;
    console.log("---->>>"+result);
    const quatre = result.length > 3 ? 4 : result.length;
    const cinq = result.length > 4 ? 5 : result.length;
    const six = result.length > 5 ? 6 : result.length;
    const sept = result.length > 6 ? 7 : result.length;
    
       if(validator.isURL(result) || result.substring(0,sept) === "http://" || result.substring(0,sept) === "https:/") {
            return ( 
                <ShowResult type="url" result={result} />
            );
        } else if ((validator.isMobilePhone(result) || result.substring(0,quatre) === "tel:")) {
            return ( 
                <ShowResult type="phone" result={result} />
              );
        } else if ((validator.isEmail(result) || result.substring(0,sept) === ("MATMSG:") || result.substring(0,sept) === ("mailto:") || result.substring(0,sept) === ("MAILTO:"))) {
            return ( 
                <ShowResult type="mail" result={result} />
              );
        } else if ((result.includes("BEGIN:VCARD") || result.includes("END:VCARD"))) {
            console.log("okkkk");
            return ( 
                <ShowResult type="vcard" result={result} />
              );
        } else if ((result.includes("BEGIN:VEVENT") || result.includes("END:VEVENT"))) {
            return ( 
                <ShowResult type="event" result={result} />
              );
        } else if ((result.substring(0,six) === ("SMSTO:") || result.substring(0,six) === ("smsto:"))) {
            return ( 
                <ShowResult type="sms" result={result} />
              );
        } else if (result.substring(0,cinq) === ("WIFI:")) {
            return ( 
                <ShowResult type="wifi" result={result} />
              );
        } else if (result.substring(0,quatre) === ("geo:")){
            return ( 
                <ShowResult type="geo" result={result} />
              );
        }  else {
            return ( 
                <ShowResult type="text" result={result} />
            );
        } 
}

export default ScanResult;